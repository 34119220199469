import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import BreakpointContext from "context/breakpointContext"
import { useChatlioContext } from "context/chatlioContext"
import { useProfileContext } from "context/profileContext"
import { useUserContext } from "context/userContext"
import { size } from "helpers/responsiveSize"
import React, { useContext } from "react"
import "styles/chatlio.scss"
import Button from "../controls/button"

/**
 * A button to activate the Chatlio widget
 */
const GetHelpButton = ({ className }) => {
  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  // Check Chatlio online status
  let { isReady, isOnline, isExpanded } = useChatlioContext()

  // User context
  const { userId, email } = useUserContext()

  // Profile context
  const { activeProfileName } = useProfileContext()

  const handleClick = () => {
    // Add visual class to widget
    var chatlioWidget = document.getElementById("chatlio-widget")
    chatlioWidget.classList.add("get-help-button")

    // Update current page for context
    window._chatlio?.page()

    // Set identification context
    window._chatlio?.identify(userId, {
      email: email,
      profile: activeProfileName,
    })

    // Show chat widget
    window._chatlio?.showOrHide()
  }

  return (
    <Button
      type="secondary"
      flat={true}
      keyline={true}
      size={size(bpt, "xs", "md", "md", "lg")}
      disabled={!isReady || isExpanded}
      onClick={handleClick}
      className={`
        w-26 tablet:w-36 laptop:w-36 desktop:w-40
        ${className ? className : ""}
      `}
    >
      <span
        className="
          uppercase font-bold 
          text-xxs tablet:text-base laptop:text-base desktop:text-lg"
      >
        Get Help
      </span>
      <FontAwesomeIcon
        icon={["fa", "comment"]}
        className={`
          ${isOnline ? "text-green" : ""}
          ml-2
          -mt-0.5
        `}
      />
    </Button>
  )
}

export default GetHelpButton
