import { useLocation } from "@reach/router"
import Metadata from "components/utils/metadata/metadata"
import { useCountries } from "context/countryContext"
import { navigate } from "gatsby"
import { useMediaQueries } from "hooks/useMediaQueries"
import GrownUps from "images/setup/grown-ups.svg"
import SetupLayout from "layouts/setupLayout"
import React from "react"
import routes from "services/routes"

//
// A warning that the store is intended for grown-ups
//
const StoreWarning = () => {
  // Grab the location from Reach Router for Metadata
  const location = useLocation()

  // Check for mobile
  const { isMobile } = useMediaQueries()

  // Store country
  const { storeURL } = useCountries()

  //
  // Handle "Next" button click
  //
  const handleNextClicked = () => {
    navigate(routes.STORE(storeURL))
  }

  return (
    <>
      <Metadata title="For Grown-ups" pathname={location.pathname} />
      <SetupLayout
        image={
          <GrownUps
            width="100%"
            height="100%"
            preserveAspectRatio={isMobile ? "xMaxYMax meet" : "xMidYMid meet"}
          />
        }
        title="Are you a grown-up?"
        subtitle={
          <>
            <p>
              We noticed that you've arrived at our web store from a player
              device, which may belong to a child.
            </p>
            <p className="mt-2">
              You may prefer to <b>use your own device</b> to sign in to our
              store!
            </p>
          </>
        }
        buttonText="Continue"
        onButtonClick={handleNextClicked}
      />
    </>
  )
}

export default StoreWarning
StoreWarning.whyDidYouRender = true
