import ChatBackdrop from "components/chat/chatBackdrop"
import GetHelpButton from "components/chat/getHelpButton"
import Button from "components/controls/button"
import BreakpointContext from "context/breakpointContext"
import { size } from "helpers/responsiveSize"
import { useMediaQueries } from "hooks/useMediaQueries"
import { useNavBar } from "hooks/useNavBar"
import React, { useContext, useMemo } from "react"
import CleanLayout from "./cleanLayout"

const SetupLayout = ({
  title,
  subtitle,
  image,
  buttonText = "Continue",
  onButtonClick,
  isButtonDisabled = false,
  childrenAfterButton = false,
  children,
}) => {
  // Media queries for alternative layouts
  const { isTablet, isLaptop, isDesktop } = useMediaQueries()

  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  // Don't Use the navbar on this page
  useNavBar({
    hasNavBar: false,
  })

  // Determine if the layout is horizontal or vertical
  const isHorizontalLayout = useMemo(() => {
    if (isTablet || isLaptop || isDesktop) {
      return true
    } else {
      return false
    }
  }, [isTablet, isLaptop, isDesktop])

  //
  // Vertical layout
  //
  const verticalLayout = useMemo(() => {
    return (
      <div
        style={{
          gridTemplateRows: "1fr 2.5fr auto",
        }}
        className="grid"
      >
        {/* Image container */}
        <div
          style={{
            height: size(bpt, "30vw"),
            width: size(bpt, "30vw"),
          }}
          className="flex justify-self-end"
        >
          {image}
        </div>
        {/* Container */}
        <div className="grid auto-rows-min">
          {/* Title */}
          <p
            style={{
              marginTop: size(bpt, "8vw"),
              marginBottom: size(bpt, "5vw"),
              fontSize: size(bpt, "7vw"),
            }}
            className="font-bold leading-snug"
          >
            {title}
          </p>
          {/* Subtitle */}
          {subtitle && (
            <div
              style={{
                fontSize: size(bpt, "4.5vw"),
              }}
            >
              {subtitle}
            </div>
          )}
          {/* Children, if any before the button */}
          <div className="flex items-center">
            {children && !childrenAfterButton && <>{children}</>}
          </div>
        </div>
        <div className="flex items-end">
          <div className="flex flex-col w-full">
            <Button
              type="primary"
              text={buttonText}
              className="w-full tablet:w-52"
              onClick={onButtonClick}
              disabled={isButtonDisabled}
            />
            {/* Children, if any after the button */}
            {children && childrenAfterButton && <>{children}</>}
          </div>
        </div>
      </div>
    )
  }, [
    bpt,
    image,
    isButtonDisabled,
    onButtonClick,
    buttonText,
    subtitle,
    title,
    childrenAfterButton,
    children,
  ])

  //
  // Horizontal layout
  //
  const horizontalLayout = useMemo(() => {
    return (
      // Container
      <div
        style={{
          gridTemplateColumns: "5.5fr 0.5fr 4fr",
          height: size(bpt, undefined, "24rem", "28rem", "30rem"),
        }}
        className="grid"
      >
        <div
          style={{
            gridTemplateRows: "1.25fr 7fr 1.75fr",
          }}
          className="grid"
        >
          {/* Spacer */}
          <div />

          {/* Content */}
          <div
            style={{
              gridTemplateRows: "repeat(3, auto) 1fr",
            }}
            className="grid auto-rows-min"
          >
            {/* Title */}
            <p
              className="
                tablet:text-3xl laptop:text-4xl desktop:text-4.5xl
                tablet:mb-4
                font-bold leading-snug tablet:leading-tight laptop:leading-snug"
            >
              {title}
            </p>

            {/* Subtitle */}
            <div className="tablet:text-lg laptop:text-xl desktop:text-2xl">
              {subtitle}
            </div>

            {/* Children, if any before the button */}
            <div className="flex items-center">
              {children && !childrenAfterButton && <>{children}</>}
            </div>

            <div className="flex items-end">
              <div className="flex flex-col">
                <Button
                  type="primary"
                  text={buttonText}
                  size={size(bpt, undefined, "md", "lg", "xl")}
                  className="tablet:w-52 laptop:w-60 desktop:w-64"
                  onClick={onButtonClick}
                  disabled={isButtonDisabled}
                />
                {/* Children, if any after the button */}
                {children && childrenAfterButton && <>{children}</>}
              </div>
            </div>
          </div>

          {/* Spacer */}
          <div />
        </div>

        {/* Spacer */}
        <div />

        {/* Image */}
        {image}
      </div>
    )
  }, [
    bpt,
    image,
    isButtonDisabled,
    onButtonClick,
    buttonText,
    subtitle,
    title,
    childrenAfterButton,
    children,
  ])

  return (
    <>
      {/* Backdrop for chat widget */}
      <ChatBackdrop />

      <CleanLayout topRightSlot={<GetHelpButton />}>
        {isHorizontalLayout && horizontalLayout}
        {!isHorizontalLayout && verticalLayout}
      </CleanLayout>
    </>
  )
}

export default SetupLayout
SetupLayout.whyDidYouRender = true
